import { replaceNullWithUndefined } from '../lib/utils/collection'

const LEFT_MOUSE_BUTTON = 0
const MIDDLE_MOUSE_BUTTON = 1
const trackedMouseButtons = [LEFT_MOUSE_BUTTON, MIDDLE_MOUSE_BUTTON]

document.addEventListener('turbolinks:load', e => {
  if (global.dataLayerPageData) {
    global.dataLayer.push({
      ...replaceNullWithUndefined(global.dataLayerPageData),
      event: 'turbo_page_view',
      url: e.data.url
    })
  }

  Array.from(document.querySelectorAll('[data-track-imp]')).forEach(item => {
    global.dataLayer.push({
      event: 'listing_imp',
      eventAction: 'impression',
      eventLabel: item.dataset.trackImp
    })
  })

  Array.from(document.querySelectorAll('[data-track-click]')).forEach(item => {
    item.addEventListener('mousedown', event => {
      if (trackedMouseButtons.includes(event.button)) {
        global.dataLayer.push({
          event: 'listing_click',
          eventAction: 'click',
          eventLabel: item.dataset.trackClick
        })
      }
    })
  })

  Array.from(document.querySelectorAll('[data-track-featured-imp]')).forEach(item => {
    global.dataLayer.push({
      event: 'custom_event',
      eventCategory: 'featured_listing_impression',
      eventAction: 'impression',
      eventLabel: item.dataset.trackFeaturedImp,
      eventValue: undefined
    })
  })

  Array.from(document.querySelectorAll('[data-track-featured-click]')).forEach(item => {
    item.addEventListener('mousedown', event => {
      if (trackedMouseButtons.includes(event.button)) {
        global.dataLayer.push({
          event: 'custom_event',
          eventCategory: 'featured_listing_click',
          eventAction: 'click',
          eventLabel: item.dataset.trackFeaturedClick,
          eventValue: undefined
        })
      }
    })
  })
})
